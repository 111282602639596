export const benefits = [
  {
    id: '1',
    iconName: 'loan',
    title: 'Plano Pontual',
    description: 'Exclusivo para antecipar seu crédito sem depender de lances.'
  },
  {
    id: '2',
    iconName: 'piggyBank',
    title: 'Parcelas reduzidas',
    description: 'Pague menos até a contemplação da sua carta de crédito.'
  },
  {
    id: '3',
    iconName: 'clock',
    title: 'Lance parcelado',
    description: 'Dê lances com liberdade, pagamento em até 4x.'
  },
  {
    id: '4',
    iconName: 'analytics',
    title: 'Lance Embutido',
    description: 'Use até 30% do valor da sua carta e antecipe sua contemplação.'
  },
  {
    id: '5',
    iconName: 'star',
    title: 'Prêmio pontualidade',
    description: 'Concorra a prêmios semanais em dinheiro.'
  },
  {
    id: '6',
    iconName: 'moneyCircle',
    title: 'Sem juros, taxa de adesão e fundo de reserva',
    description: 'Conquista que não pesa no bolso.'
  }
]
