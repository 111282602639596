import { FC, memo } from 'react'

import { Text } from '@cash-me/react-components'

import { Benefits as BenefitsModule } from 'modules/benefits'

import { benefits } from './data'

const Benefits: FC = memo(() => (
  <BenefitsModule benefits={benefits} columns={3}>
    <Text as="h2" size="heading3" weight="medium">
      <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
        Vantagens
      </Text>{' '}
      do consórcio CashMe
    </Text>
    <Text size="heading5" color="var(--colors-neutral-text-weak)" css={{ marginBottom: 'var(--space-spacing-md)' }}>
      Quer mais motivos para escolher o consórcio? Confira:
    </Text>
  </BenefitsModule>
))

Benefits.displayName = 'Benefits'

export default Benefits
